import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import { ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import Parser from 'html-react-parser';
import { isEmpty } from '../lib/utils'

export default function Person_Details({ location, pageContext }) {
    let quoteComponent;

    if (!isEmpty(pageContext.people.quote)) {
        quoteComponent = <div className="w-full   mt-8 lg:mt-0  flex flex-col justify-start items-start ">
        <div className="max-w-xl mt-8 ">
            <span className="inline-block mb-5">
                <svg width={36} height={36} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M26 29.45C26 19.3858 33.4577 8.86747 45.2297 6.16443C45.6793 6.0612 46.1364 6.29467 46.3301 6.71327L47.5161 9.27572C47.7602 9.80299 47.5031 10.4238 46.9877 10.692C43.3044 12.608 40.1644 17.1966 39.3647 20.8059C39.2493 21.3268 39.6241 21.8042 40.1413 21.9351C44.6581 23.0784 48 27.1681 48 32.042C48 38.406 42.832 42 37.602 42C31.572 42 26 37.39 26 29.45ZM0 29.45C0 19.3858 7.45772 8.86747 19.2297 6.16443C19.6793 6.0612 20.1364 6.29467 20.3301 6.71327L21.5161 9.27572C21.7602 9.80299 21.5031 10.4238 20.9877 10.692C17.3044 12.608 14.1644 17.1966 13.3647 20.8059C13.2493 21.3268 13.6241 21.8042 14.1413 21.9351C18.6581 23.0784 22 27.1681 22 32.042C22 38.406 16.832 42 11.602 42C5.572 42 0 37.39 0 29.45Z" fill="#f05a22" />
                </svg>
            </span>
            <p className="mb-5 lg:font-body2 xl:font-body1">{pageContext.people.quote} </p>
        </div>
    </div>;
      } else {
        quoteComponent = <div></div>;
      }

    return (
        <Layout location={location}  >
            <div className="space-y-4 max-w-full bg-sky-50 relative  min-h-screen">
                <div className="flex justify-start ml-16 pt-16">
                    <Link to={"/people/" + pageContext.index} >   <div className="">
                        {/* <ArrowLeftIcon className="inline h-12 w-12" aria-hidden="false" />  */}
                        <h2 className="inline text-sm font-bold leading-normal text-gray-600  hover:text-orange-primary">Back to team </h2> </div>
                    </Link>
                </div>
                <div className="bg-sky-50 px-4">
                    {
                        pageContext.prev && (<Link className="visible" to={pageContext.prev} state={{ index: pageContext.index }}
                        >
                            <ChevronLeftIcon className="absolute top-32 left-8 h-12 w-12 text-gray-400 hover:text-gray-800" aria-hidden="false" />
                        </Link>)
                    }

                    <div className="flex flex-col lg:flex-row">
                        <div className=" flex flex-col justify-center items-center h-full w-full lg:w-1/3 ">

                            <div className=" rounded-full pt-8">
                                <img src={pageContext.people.profile_pic.url} alt="Profile pic" className="h-72 w-72  rounded-full" />
                            </div>
                            <div className=" flex w-full justify-center items-center text-lg my-8">

                                <div className="font-medium ml-2 mt-8 text-sm md:text-base md:space-y-1 flex  ">
                                    {/* <h3 className="block text-gray-700 text-2xl md:text-3xl px-10">{pageContext.people.name}
                                    </h3> */}
                                    <a href={pageContext.people.linkedin_url} target="_blank" >
                                        <svg
                                            className="w-10 h-10  text-blue-500 fill-current"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512">
                                            <path
                                                d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                                            ></path>
                                        </svg>
                                    </a>

                                </div>

                            </div>
                            {/* <div className=" border-4 rounded-xl w-30v ml-8 ">
                                <div className="flex justify-center text-xl font-semibold font-raleway p-2">
                                    Expertise:
                                </div>
                                <div className="flex justify-center py-3 pb-3">
                                    {pageContext.people.speciality}
                                </div>
                            </div> */}
                        </div>
                        <div className="text-center lg:text-left mt-8 w-full lg:w-2/3 px-2  lg:px-24">
                            <div className=" font-heading1 "> {pageContext.people.name} </div>
                            {/* <div className=" text-bright-orange"> {pageContext.people.role} </div> */}
                            <div className=" font-body1 "><span className="text-orange-primary"> {pageContext.people.designation} </span></div>
                            {/* <p className="block  tracking-wide font-light text-gray-600 text-xl  lg:text-lg xl:text-xl mt-8 leading-loose text-justify"> {Parser(pageContext.people.bio)}
                            </p> */}
                            <div className=" text-justify font-body1  lg:mt-16">
                                {Parser(pageContext.people.bio)}
                            </div>
                            {quoteComponent}
                        </div>
                    </div>
                    {
                        pageContext.next &&
                        (<Link className="visible" to={pageContext.next}>     <ChevronRightIcon className="absolute top-32 right-8 h-12 w-12 text-gray-400 hover:text-gray-800" aria-hidden="false" /> </Link>)
                    }

                </div>
            </div>

        </Layout>

    )
}